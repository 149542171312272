import { Button, Flex, Heading } from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'
import styled, { keyframes, css } from 'styled-components'
import Lottie from 'react-lottie'
import { Link, scroller } from 'react-scroll'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: {
    v: '5.5.2',
    fr: 30,
    ip: 0,
    op: 90,
    w: 500,
    h: 500,
    nm: 'Comp 1',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'Ball',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 5, s: [100] },
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 60, s: [100] },
              { t: 70, s: [0] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: { a: 0, k: 250, ix: 3 },
            y: {
              a: 1,
              k: [
                { i: { x: [0.689], y: [1] }, o: { x: [0.213], y: [1.081] }, t: 10, s: [255] },
                { i: { x: [0.284], y: [1] }, o: { x: [0.918], y: [0] }, t: 24, s: [242.838] },
                { i: { x: [0.348], y: [1] }, o: { x: [0.589], y: [-0.009] }, t: 50, s: [426.703] },
                { t: 60, s: [409.703] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [113.99999999999997, 113.99999999999997, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [28.594, 28.594], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0.297, -72.703], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 90,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'Mouse',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { s: true, x: { a: 0, k: 250, ix: 3 }, y: { a: 0, k: 250, ix: 4 } },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        hasMask: true,
        masksProperties: [
          {
            inv: false,
            mode: 'f',
            pt: {
              a: 0,
              k: {
                i: [
                  [0, 0],
                  [0, 0],
                  [0, -58.818],
                  [0, 0],
                  [-58.818, 0],
                  [0, 0],
                  [0, 58.818],
                  [0, 0],
                  [58.818, 0],
                ],
                o: [
                  [0, 0],
                  [-58.818, 0],
                  [0, 0],
                  [0, 58.818],
                  [0, 0],
                  [58.818, 0],
                  [0, 0],
                  [0, -58.818],
                  [0, 0],
                ],
                v: [
                  [0.5, -186.5],
                  [0.5, -186.5],
                  [-106, -80],
                  [-106, 81],
                  [0.5, 187.5],
                  [0.5, 187.5],
                  [107, 81],
                  [107, -80],
                  [0.5, -186.5],
                ],
                c: true,
              },
              ix: 1,
            },
            o: { a: 0, k: 100, ix: 3 },
            x: { a: 0, k: 0, ix: 4 },
            nm: 'Mask 1',
          },
        ],
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-47.696, 0],
                      [0, -47.696],
                      [0, 0],
                      [47.696, 0],
                      [0, 47.696],
                      [0, 0],
                    ],
                    o: [
                      [47.696, 0],
                      [0, 0],
                      [0, 47.696],
                      [-47.696, 0],
                      [0, 0],
                      [0, -47.696],
                    ],
                    v: [
                      [0, -167],
                      [86.5, -80.5],
                      [86.5, 80.5],
                      [0, 167],
                      [-86.5, 80.5],
                      [-86.5, -80.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 18, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 90,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  },
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const scrollToSection = () => {
  scroller.scrollTo('home-1', {
    duration: 800, // 스크롤 애니메이션 duration (ms)
    delay: 0, // 스크롤 애니메이션 delay (ms)
    smooth: 'easeInOutQuart', // 스크롤 애니메이션 easing 함수
  })
}

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  background: url('/images/section1_background.png');
  background-color: #0a1721;
  background-size: cover;
  background-repeat: no-repeat;
`

const StyledFlex = styled(Flex)`
  ${css`
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      height: 60vw;
      width: 100%;
    }

    @media screen and (min-width: 1025px) {
      height: 70%;
      width: 100%;
      margin-top: 2rem;
    }
  `}
`

const Hero = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
      <BgWrapper />
      <StyledFlex
        flex={[null, null, null, '1']}
        mb={['24px', null, null, '0']}
        position="relative"
        alignItems="center" // Flex 컨테이너의 아이템을 수직 가운데 정렬
        justifyContent="center"
      >
        <Flex position="relative" flexDirection="column" justifyContent="center" width="100%" height="100%">
          <Heading
            scale="xxl"
            color="#FFF"
            mb="24px"
            width="100%"
            textAlign={window.innerWidth > 768 ? 'left' : 'center'}
          >
            {t('Welcome to')}
          </Heading>
          <Heading
            scale="xxxl"
            color="#f0aa1e"
            mb="24px"
            width="100%"
            textAlign={window.innerWidth > 768 ? 'left' : 'center'}
          >
            {t('yesBswap')}
          </Heading>
          <Heading
            scale="md"
            mb="24px"
            width="100%"
            textAlign={window.innerWidth > 768 ? 'left' : 'center'}
            color="#AEAEAE"
          >
            {t('yesBswap is a DEX built on Klaytn. Enjoy the optimize of profit with low fee.')}
          </Heading>
          <Flex alignItems="center" justifyContent={window.innerWidth > 768 ? 'left' : 'center'}>
            {!account && <ConnectWalletButton mr="8px" />}
            <NextLinkFromReactRouter to="/swap">
              <Button variant={!account ? 'secondary' : 'primary'}>{t('Trade Now')}</Button>
            </NextLinkFromReactRouter>
          </Flex>
        </Flex>
      </StyledFlex>
      <div
        style={{
          bottom: '10%',
          transform: 'translateX(-40px)',
          position: 'absolute',
          left: '50%',
        }}
      >
        <p
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            textAlign: 'center',
            color: 'white',
            opacity: 0.74,
          }}
        >
          Scroll Down
        </p>
        <Link to="home-1" smooth offset={-70} duration={800} onClick={scrollToSection}>
          <Lottie options={defaultOptions} height={43} width={60} />
        </Link>
      </div>
    </>
  )
}

export default Hero
