import styled from 'styled-components'
import PageSection from 'components/PageSection'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import Hero from './components/Hero'
import Footer from './components/Footer'
import About from './components/About'

const StyledHeroSection = styled(PageSection)`
  height: 100vh;
  padding-top: 16px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageMeta />
      <StyledHeroSection innerProps={{ style: { margin: '2rem 0', width: '100%' } }} index={1} hasCurvedDivider={false}>
        <Hero />
      </StyledHeroSection>

      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', marginBottom: '2rem' } }}
        background="#0A1721"
        containerProps={{
          id: 'home-1',
        }}
        index={1}
        paddingBottom="0"
        hasCurvedDivider={false}
      >
        <About />
      </PageSection>

      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', marginBottom: '2rem' } }}
        background="#000"
        containerProps={{
          id: 'home-2',
        }}
        index={2}
        paddingBottom="0"
        hasCurvedDivider={false}
      >
        <Footer />
      </PageSection>
    </>
  )
}

export default Home
