import { GetStaticProps } from 'next'
import { SWRConfig } from 'swr'
import Home from '../views/Home'

const IndexPage = ({ totalTx30Days, addressCount30Days, tvl }) => {
  return (
    <SWRConfig
      value={{
        fallback: {
          totalTx30Days,
          addressCount30Days,
          tvl,
        },
      }}
    >
      <Home />
    </SWRConfig>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  }
}

IndexPage.chains = []

export default IndexPage
