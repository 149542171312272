import { Flex, Heading } from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import { useTranslation } from '@pancakeswap/localization'
import styled, { css } from 'styled-components'

const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 1.5rem;
  align-items: center;
  margin: 4rem 0;
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const StyledGrid = styled.div`
  display: grid;
  gap: 1rem;
  justify-items: center;
  align-items: center;
`
const StyledFlex = styled(Flex)`
  ${css`
    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column-reverse;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      height: 60vw;
      width: 100%;
    }

    @media screen and (min-width: 1025px) {
      height: 70%;
      width: 100%;
    }
  `}
`

const About = () => {
  const { t } = useTranslation()

  return (
    <>
      <BgWrapper>{/* <InnerWrapper>{<SlideSvgLight width="100%" />}</InnerWrapper> */}</BgWrapper>
      <StyledFlex
        flex={[null, null, null, '1']}
        mb={['24px', null, null, '0']}
        position="relative"
        alignItems="center" // Flex 컨테이너의 아이템을 수직 가운데 정렬
        justifyContent="center"
      >
        <Flex position="relative" flexDirection="column" justifyContent="center" width="100%" height="100%">
          <Heading scale="xl" color="#FFF" mb="30px" width="100%" textAlign="center">
            {t('Discover the')}
            <span style={{ color: '#fff' }}> yes</span>
            <span style={{ color: '#e72019' }}>B</span>
            <span style={{ color: '#fff' }}>swap</span>
          </Heading>
          <Heading scale="xl" mb="1px" width="100%" textAlign="center" color="#FFF">
            {t('Gateway of BTC2 that anyone can easily use.')}
          </Heading>

          <ResponsiveGrid>
            <StyledGrid>
              <img src="/images/decentralization.png" alt="decentralization" />
              <Heading scale="lg" mb="1px" width="100%" textAlign="center" color="#FFF">
                {t('SWAP')}
              </Heading>
              <Heading scale="md" mb="1px" width="100%" textAlign="center" color="#A7A7A7">
                {t('Swap your asset to BTC2')}
              </Heading>
            </StyledGrid>
            <StyledGrid>
              <img src="/images/profitability.png" alt="profitability" />
              <Heading scale="lg" mb="1px" width="100%" textAlign="center" color="#FFF">
                {t('DECENTRALIZATION')}
              </Heading>
              <Heading scale="md" mb="1px" width="100%" textAlign="center" color="#A7A7A7">
                {t('Own your BTC2. Optimize your profit')}
              </Heading>
            </StyledGrid>
            <StyledGrid>
              <img src="/images/valuable.png" alt="valuable" />
              <Heading scale="lg" mb="1px" width="100%" textAlign="center" color="#FFF">
                {t('VALUE')}
              </Heading>
              <Heading scale="md" mb="1px" width="100%" textAlign="center" color="#A7A7A7">
                {t('BTC2 strongly deny centralized action')}
              </Heading>
            </StyledGrid>
          </ResponsiveGrid>
        </Flex>
      </StyledFlex>
    </>
  )
}

export default About
